import React from 'react'
import styles from './Bio.module.css'

function Bio(props) {
  return (
    <div className={`
      ${styles.Bio}
      ${props.className || ''}
    `}>
      <img src="https://netodevel.com/eu-p.jpeg" alt="Me" />
      <p>
        Líder de engenharia na <a href='https://orla.tech'>Orla</a><br/>
        Blog destinado a documentar meus desafios e experimentos.
      </p>
    </div>
  )
}

export default Bio
